<template>
  <div>
    <header-slot />
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['deletedCards'].refresh()"
    >
      <template #table>
        <b-table
          ref="deletedCards"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          :items="deletedCardsProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(lead_name)="data">
            <view-client-details
              :client-information="{
                ...data.item,
                client_name: data.item.lead_name,
              }"
            />
          </template>
          <template #cell(created_at)="data">
            {{ data.item.created_by_name }}
            <br />
            {{ data.item.created_at | myGlobalWithHour }}
          </template>
          <template #cell(updated_at)="data">
            {{ data.item.updated_at | myGlobalWithHour }}
          </template>
          <template #cell(card_number)="data">
            <p class="mb-0 font-weight-bold">
              {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
            </p>
          </template>
          <template #cell(deleted_at)="data">
            {{ data.item.deleted_by_name }}
            <br />
            {{ data.item.deleted_at | myGlobalWithHour }}
          </template>
          <template #cell(seen)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                v-if="data.item.seen_at == null"
                v-b-tooltip.hover.top="'Mark as seen'"
                icon="EyeIcon"
                class="text-primary cursor-pointer"
                @click="markAsSeen(data.item)"
              />
              <feather-icon v-else icon="CheckIcon" class="text-success" />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import DeletedCardsService from "@/views/ce-digital/sub-modules/financial/view/deleted-cards/deleted-cards.service";
import DeletedCardsFilters from "@/views/ce-digital/sub-modules/financial/view/deleted-cards/data/deleted-cards.filters";
import { mapGetters, mapMutations } from "vuex";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue"; // <--- import here
export default {
  components: {
    ViewClientDetails, // <--- add here
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      totalRows: 0,
      sortBy: "deleted_at",
      sortDesc: true,
      items: [],
      filters: DeletedCardsFilters,
      fields: [
        {
          key: "lead_name",
          sortable: false,
          label: "Client",
          visible: true,
        },
        {
          key: "cardholdername",
          sortable: false,
          label: "Card Holder Name",
          visible: true,
        },
        {
          key: "card_number",
          sortable: false,
          label: "Card Number",
        },
        {
          key: "type_card",
          sortable: false,
          label: "Type",
          visible: true,
        },
        {
          key: "card_expi_month",
          sortable: false,
          label: "MM",
          visible: true,
        },
        {
          key: "card_expi_year",
          sortable: false,
          label: "YY",
          visible: true,
        },
        {
          key: "created_at",
          sortable: true,
          label: "Created By",
          visible: true,
        },
        {
          key: "updated_at",
          sortable: false,
          label: "Updated At",
          visible: true,
        },
        {
          key: "deleted_at",
          sortable: true,
          label: "Deleted By",
          visible: true,
        },
        {
          key: "seen",
          sortable: true,
          label: "Seen",
          visible: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    ...mapMutations({
      countPending: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),
    async deletedCardsProvider(ctx) {
      const parameters = {
        text: this.filterPrincipal.model,
        dateFrom: this.filters[0].model,
        dateTo: this.filters[1].model,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        orderBy: ctx.sortBy ? ctx.sortBy : "created_at",
        order: ctx.sortDesc ? "desc" : "asc",
      };
      const data2 = await DeletedCardsService.getNotSeenDeletedCards();
      const payload = {
        routeName: "ce-digital-deleted-cards",
        tag: data2.data > 99 ? "99+" : data2.data,
      };
      await this.countPending(payload);
      const data = await DeletedCardsService.getDeletedCards(parameters);
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
    async markAsSeen(item) {
      item.seen_at = 1;
      await DeletedCardsService.markAsSeen({
        userId: this.currentUser.user_id,
        cardLeadId: item.id,
      });
      const data = await DeletedCardsService.getNotSeenDeletedCards();
      const payload = {
        routeName: "ce-digital-deleted-cards",
        tag: data.data > 99 ? "99+" : data.data,
      };
      await this.countPending(payload);
    },
  },
};
</script>

<style scoped>
</style>
